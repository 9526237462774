@import url(https://fonts.googleapis.com/css2?family=Tenor+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700&display=swap);
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

ol, ul {
    list-style-position: inside;
}

/* Avenir-Light - latin */

@font-face {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 300;
    src: url(/static/media/Avenir-Light.226f6f57.eot); /* IE9 Compat Modes */
    src: local(''),
    url(/static/media/Avenir-Light.226f6f57.eot?#iefix) format('embedded-opentype'), 
    url(/static/media/Avenir-Light.867b51dd.woff2) format('woff2'), 
         url(/static/media/Avenir-Light.05ad512f.woff) format('woff'), 
         url(/static/media/Avenir-Light.e4e0f6ea.ttf) format('truetype'), 
         url(/static/media/Avenir-Light.2bd660ee.svg#Avenir) format('svg'); /* Legacy iOS */
  }

/* Avenir-Book - latin */
@font-face {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 400;
    src: url(/static/media/Avenir-Book.8c3f881f.eot); /* IE9 Compat Modes */
    src: local(''),
         url(/static/media/Avenir-Book.8c3f881f.eot?#iefix) format('embedded-opentype'), 
         url(/static/media/Avenir-Book.a241fb31.woff2) format('woff2'), 
         url(/static/media/Avenir-Book.10c550ec.woff) format('woff'), 
         url(/static/media/Avenir-Book.f3443d08.ttf) format('truetype'), 
         url(/static/media/Avenir-Book.000f9847.svg#Avenir) format('svg'); /* Legacy iOS */
  }

     /* Avenir-Roman - latin */
     @font-face {
        font-family: 'Avenir';
        font-style: normal;
        font-weight: 500;
        src: url(/static/media/Avenir-Roman.2fe06624.eot); /* IE9 Compat Modes */
        src: local(''),
             url(/static/media/Avenir-Roman.2fe06624.eot?#iefix) format('embedded-opentype'), 
             url(/static/media/Avenir-Roman.cf8485cd.woff2) format('woff2'), 
             url(/static/media/Avenir-Roman.75874ae2.woff) format('woff'), 
             url(/static/media/Avenir-Roman.0290d99c.ttf) format('truetype'), 
             url(/static/media/Avenir-Roman.c042c3c7.svg#Avenir) format('svg'); /* Legacy iOS */
      }

  /* Avenir-Medium - latin */
  @font-face {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 600;
    src: url(/static/media/Avenir-Medium.c2c16bbf.eot); /* IE9 Compat Modes */
    src: local(''),
         url(/static/media/Avenir-Medium.c2c16bbf.eot?#iefix) format('embedded-opentype'), 
         url(/static/media/Avenir-Medium.25455e7d.woff2) format('woff2'), 
         url(/static/media/Avenir-Medium.3cceca82.woff) format('woff'), 
         url(/static/media/Avenir-Medium.a11c4a4f.ttf) format('truetype'), 
         url(/static/media/Avenir-Medium.68ac8497.svg#Avenir) format('svg'); /* Legacy iOS */
  }
 
  /* Avenir-Heavy - latin */
  @font-face {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 700;
    src: url(/static/media/Avenir-Heavy.f7fd55a1.eot); /* IE9 Compat Modes */
    src: local(''),
         url(/static/media/Avenir-Heavy.f7fd55a1.eot?#iefix) format('embedded-opentype'), 
         url(/static/media/Avenir-Heavy.8698cb53.woff2) format('woff2'), 
         url(/static/media/Avenir-Heavy.62c1855a.woff) format('woff'), 
         url(/static/media/Avenir-Heavy.7bde6752.ttf) format('truetype'), 
         url(/static/media/Avenir-Heavy.6dc3704d.svg#Avenir) format('svg'); /* Legacy iOS */
  }
  /* Avenir-Black - latin */
  @font-face {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 900;
    src: url(/static/media/Avenir-Black.f277ad39.eot); /* IE9 Compat Modes */
    src: local(''),
         url(/static/media/Avenir-Black.f277ad39.eot?#iefix) format('embedded-opentype'), 
         url(/static/media/Avenir-Black.c9c45701.woff2) format('woff2'), 
         url(/static/media/Avenir-Black.7f911333.woff) format('woff'), 
         url(/static/media/Avenir-Black.be9ee6dd.ttf) format('truetype'), 
         url(/static/media/Avenir-Black.742468bd.svg#Avenir) format('svg'); /* Legacy iOS */
  }

