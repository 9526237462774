* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

ol, ul {
    list-style-position: inside;
}

/* Avenir-Light - latin */

@font-face {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 300;
    src: url('./assets/fonts/Avenir-Light.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('./assets/fonts/Avenir-Light.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./assets/fonts/Avenir-Light.woff2') format('woff2'), /* Super Modern Browsers */
         url('./assets/fonts/Avenir-Light.woff') format('woff'), /* Modern Browsers */
         url('./assets/fonts/Avenir-Light.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./assets/fonts/Avenir-Light.svg#Avenir') format('svg'); /* Legacy iOS */
  }

/* Avenir-Book - latin */
@font-face {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 400;
    src: url('./assets/fonts/Avenir-Book.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./assets/fonts/Avenir-Book.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./assets/fonts/Avenir-Book.woff2') format('woff2'), /* Super Modern Browsers */
         url('./assets/fonts/Avenir-Book.woff') format('woff'), /* Modern Browsers */
         url('./assets/fonts/Avenir-Book.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./assets/fonts/Avenir-Book.svg#Avenir') format('svg'); /* Legacy iOS */
  }

     /* Avenir-Roman - latin */
     @font-face {
        font-family: 'Avenir';
        font-style: normal;
        font-weight: 500;
        src: url('./assets/fonts/Avenir-Roman.eot'); /* IE9 Compat Modes */
        src: local(''),
             url('./assets/fonts/Avenir-Roman.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
             url('./assets/fonts/Avenir-Roman.woff2') format('woff2'), /* Super Modern Browsers */
             url('./assets/fonts/Avenir-Roman.woff') format('woff'), /* Modern Browsers */
             url('./assets/fonts/Avenir-Roman.ttf') format('truetype'), /* Safari, Android, iOS */
             url('./assets/fonts/Avenir-Roman.svg#Avenir') format('svg'); /* Legacy iOS */
      }

  /* Avenir-Medium - latin */
  @font-face {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 600;
    src: url('./assets/fonts/Avenir-Medium.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./assets/fonts/Avenir-Medium.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./assets/fonts/Avenir-Medium.woff2') format('woff2'), /* Super Modern Browsers */
         url('./assets/fonts/Avenir-Medium.woff') format('woff'), /* Modern Browsers */
         url('./assets/fonts/Avenir-Medium.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./assets/fonts/Avenir-Medium.svg#Avenir') format('svg'); /* Legacy iOS */
  }
 
  /* Avenir-Heavy - latin */
  @font-face {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 700;
    src: url('./assets/fonts/Avenir-Heavy.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./assets/fonts/Avenir-Heavy.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./assets/fonts/Avenir-Heavy.woff2') format('woff2'), /* Super Modern Browsers */
         url('./assets/fonts/Avenir-Heavy.woff') format('woff'), /* Modern Browsers */
         url('./assets/fonts/Avenir-Heavy.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./assets/fonts/Avenir-Heavy.svg#Avenir') format('svg'); /* Legacy iOS */
  }
  /* Avenir-Black - latin */
  @font-face {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 900;
    src: url('./assets/fonts/Avenir-Black.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./assets/fonts/Avenir-Black.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./assets/fonts/Avenir-Black.woff2') format('woff2'), /* Super Modern Browsers */
         url('./assets/fonts/Avenir-Black.woff') format('woff'), /* Modern Browsers */
         url('./assets/fonts/Avenir-Black.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./assets/fonts/Avenir-Black.svg#Avenir') format('svg'); /* Legacy iOS */
  }